import client from './client';
import { orderInformationIState } from 'types/order';

export const getOrderListByCustomerID = (userEmail: string) =>
  client.post(`/order/list`, { userEmail }, { withCredentials: true });

export const saveOrderInformation = (
  orderInformation: orderInformationIState,
) =>
  client.post(
    '/order/saveOrd',
    { orderInformation },
    { withCredentials: true },
  );

export const updateOrderInformation = (
  orderId: string,
  paymentKey: string,
  virtualAccountBank: string | null,
  virtualAccountNumber: string | null,
  secret: string | null,
) =>
  client.post('/order/cmpOrd', {
    orderId,
    paymentKey,
    virtualAccountBank,
    virtualAccountNumber,
    secret,
  });

export const getVirtualAccountOrderInformation = (orderId: string) =>
  client.post('/order/getVAOrd', { orderId });

export const getOrderInformation = () => client.get('/order/getShipInfo');
