import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './modules';
import { CookiesProvider } from 'react-cookie';
import { tempSetUser, check } from 'modules/user';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

// function loadUser() {
//   try {
//     const user = localStorage.getItem('user');
//     // 로그인 상태가 아니라면 아무것도 안 함
//     if (!user) return;
//     store.dispatch(tempSetUser(user));
//     store.dispatch(check());
//   } catch (e) {}
// }

// sagaMiddleware.run(rootSaga);
// loadUser();

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <React.Fragment>
        <App />
      </React.Fragment>
    </Provider>
    <script type="../src/components/mobion/main.js"></script>
  </CookiesProvider>,
  document.getElementById('root'),
);
