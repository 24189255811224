import axios from 'axios';

const client = axios.create();

/** Main Page */
client.defaults.baseURL = 'https://blackhats.co.kr:8080/';

/** Test Page */
// client.defaults.baseURL = 'https://blackhatsdata.com:8080/';

export default client;
