import React from 'react';
import styled from 'styled-components';
import { UserOrderInformationIState } from 'types/order';

import OrderList from 'components/myPage/OrderList';

function MyPagePresenter({ isPC, userOrderList }: MyPageIState) {
  return (
    <Container isPC={isPC}>
      <ContentContainer>
        <TitleContainer>
          <Title>구매 내역</Title>
        </TitleContainer>
        <OrderList userOrderList={userOrderList} />
      </ContentContainer>
      <ContentContainer>
        <TitleContainer>
          <Title>내 정보</Title>
        </TitleContainer>
      </ContentContainer>
    </Container>
  );
}
export default MyPagePresenter;

interface MyPageIState {
  isPC: boolean;
  userOrderList: Array<UserOrderInformationIState>;
}

const Container = styled.div`
  width: 100vw;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  background: black;
  margin-top: ${(props: { isPC: boolean }) => (props.isPC ? '150' : '75')}px;
  margin-bottom: ${(props: { isPC: boolean }) =>
    props.isPC ? '200' : '100'}px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid white;
`;

const TitleContainer = styled.div`
  width: 100%;

  padding: 1rem;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 10px;
`;
