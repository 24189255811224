import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const globalStyles = createGlobalStyle`
    ${reset};
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    
    html {
        width: 100%;
        height: 100%;
    }
    body{
        width: 100%;
        height: 100%;
        position: relative;
        
        background-color: #f2f2f2;
        
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: #070707;
        overflow-x:hidden;

        z-index: 0;
    }
    
    a{
        text-decoration:none;
        color:inherit;
    }
`;

export default globalStyles;
