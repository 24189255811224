import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from './auth';
import user, { userSaga } from './user';
import loading from './loading';
import header from './header';
import responsive from './responsive';

const rootReducer = combineReducers({
  // auth,
  loading,
  // user,
  header,
  responsive,
});

// export function* rootSaga() {
//   yield all([authSaga(), userSaga()]);
// }

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
