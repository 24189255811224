import React from 'react';
import styled from 'styled-components';
import { UserOrderInformationIState } from 'types/order';

function MyPagePresenter({ userOrderList }: MyPageIState) {
  return (
    <>
      <OrderListContainer>
        {userOrderList &&
          userOrderList.map((orderItem, index) => {
            return (
              <OrderItemContainer key={index}>
                <div> {orderItem.createdDate}</div>
                <div> {orderItem.status}</div>
                {orderItem.orderProducts.map((product, index) => {
                  return (
                    <div>
                      <span>{product.seasonTitle}</span>
                      <span>{product.subTitle}</span>
                      <span>{product.kinds}</span>
                      <span>{product.amount}</span>
                      <span>{product.price}</span>
                      <span>{product.productId}</span>
                    </div>
                  );
                })}
              </OrderItemContainer>
            );
          })}
      </OrderListContainer>
    </>
  );
}
export default MyPagePresenter;

interface MyPageIState {
  userOrderList: Array<UserOrderInformationIState>;
}

const OrderListContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  border: 1px solid white;
`;

const OrderItemContainer = styled.div`
  border: 1px solid white;
`;
