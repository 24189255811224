import React, { useState, useEffect, Suspense, lazy } from 'react';
import {
  RouteComponentProps,
  withRouter,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withCookies } from 'react-cookie';

import Loading from 'components/loading';

import MyPage from 'routes/myPage';
import Payment from 'routes/payment';

const Header = lazy(() => import('components/header'));
const MobileHeader = lazy(() => import('components/header/mobile'));
const Footer = lazy(() => import('components/footer'));
const ScrollTo = lazy(() => import('components/scrollTo'));
const Home = lazy(() => import('routes/home'));
const SignIn = lazy(() => import('routes/auth/signIn'));
const SignUp = lazy(() => import('routes/auth/signUp'));
const HowToPlay = lazy(() => import('routes/howtoplay'));
const Store = lazy(() => import('routes/store'));
const StoreDetail = lazy(() => import('routes/store/detail/season2'));
const StoreDetailSeason1 = lazy(() => import('routes/store/detail/season1'));
const PaymentWait = lazy(() => import('routes/payment/wait'));
const PaymentVirtualAccount = lazy(
  () => import('routes/payment/wait/virtualAccountPayment'),
);
const PaymentSuccess = lazy(() => import('routes/payment/success'));
const PaymentFail = lazy(() => import('routes/payment/fail'));
const PaymentInformation = lazy(() => import('routes/shipping'));
const Hint = lazy(() => import('routes/hint'));

const Pages: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  const { isPC } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );

  useEffect(() => {
    appendMobionScript();
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && isPC) setShowFooter(false);
    else setShowFooter(true);
  }, [location, isPC]);

  return (
    <Suspense fallback={<Loading backgroundColor="black" />}>
      {isPC ? <Header /> : <MobileHeader />}
      <ScrollTo>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={SignIn} />
          <Route path="/register" exact component={SignUp} />
          <Route path="/howtoplay" exact component={HowToPlay} />
          <Route path="/store/0" exact component={StoreDetailSeason1} />
          <Route path="/store" exact component={Store} />
          {/* <Route path="/store/payment" exact component={Payment} />
          <Route
            path="/store/payment/wait/virtualAccount"
            component={PaymentVirtualAccount}
          />
          <Route path="/store/payment/wait/" component={PaymentWait} />
          <Route
            path="/store/payment/success"
            exact
            component={PaymentSuccess}
          />
          <Route path="/store/payment/fail" exact component={PaymentFail} /> */}
          <Route path="/store/:index" exact component={StoreDetail} />
          <Route path="/hint" exact component={Hint} />
          <Route path="/myPage" exact component={MyPage} />
          {/* <Route path="/sangoki" exact component={PaymentInformation} /> */}
          <Redirect from="/hint/1" to="/hint" />
          <Redirect from="*" to="/" />
        </Switch>
        {showFooter && <Footer />}
      </ScrollTo>
    </Suspense>
  );
};

export default withCookies(withRouter(Pages));

function appendMobionScript() {
  const script = document.createElement('script');
  script.append(
    "(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src=`https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js`;t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,'script');enp('create', 'common', 'blackhats', { device: 'W' });enp('send', 'common', 'blackhats');",
  );
  document.body.appendChild(script);
}
