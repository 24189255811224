import { createAction, handleActions } from 'redux-actions';

const LANDING_CURRENT_PAGE = 'header/LANDING_CURRENT_PAGE';
const INCREASE = 'header/LANDING_CURRENT_PAGE_INCREASE';
const DECREASE = 'header/LANDING_CURRENT_PAGE_DECREASE';

export const getCurrentPage = createAction(
  LANDING_CURRENT_PAGE,
  (currentPage: number) => currentPage,
);
export const currentPageIncrease = () => ({ type: INCREASE });
export const currentPageDecrease = () => ({ type: DECREASE });

const initialState: InterfaceState = {
  currentPage: 0,
};

interface InterfaceState {
  currentPage: number;
}

const header = handleActions<InterfaceState, any>(
  {
    [LANDING_CURRENT_PAGE]: (state, { payload: pageNumber }) => ({
      ...state,
      currentPage: pageNumber,
    }),
    [INCREASE]: (state) => ({
      ...state,
      currentPage: state.currentPage + 1,
    }),
    [DECREASE]: (state) => ({
      ...state,
      currentPage: state.currentPage - 1,
    }),
  },
  initialState,
);

export default header;
