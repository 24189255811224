import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';

const LoaderComponent = (props: propsIState) => {
  const { backgroundColor = 'rgba(0, 0, 0, 0.5)' } = props;

  return (
    <Container backgroundColor={backgroundColor}>
      <Loader type="Oval" color="#020202" height={100} width={100} />
    </Container>
  );
};

export default LoaderComponent;

interface propsIState {
  backgroundColor?: string;
}

const Container = styled.div<{ backgroundColor: string }>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.backgroundColor};
  /* overflow: hidden; */
  z-index: 999;
`;
