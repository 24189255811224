import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getOrderListByCustomerID } from 'lib/api/order';
import MyPagePresenter from './myPagePresenter';

import { UserOrderInformationIState } from 'types/order';

function MyPageContainer() {
  const history = useHistory();
  const { isPC } = useSelector(
    ({ responsive }: { responsive: responsiveIState }) => responsive.screenType,
  );
  const { user } = useSelector(({ user }: any) => ({
    user: user.user,
  }));

  const [userOrderList, setUserOrderList] = useState(
    [] as Array<UserOrderInformationIState> | [],
  );

  useEffect(() => {
    if (user.userEmail === null) {
      // alert('로그인 후 이용해주세요');
      // history.push('/login');
    }
    try {
      getOrderListByCustomerID(user.userEmail).then((res) => {
        console.log(res);
        setUserOrderList(res.data);
      });
    } catch {
      alert('서버 오류');
      history.push('/');
    }
  }, [user, history]);

  return <MyPagePresenter isPC={isPC} userOrderList={userOrderList} />;
}
export default MyPageContainer;
